import { AsyncPipe, NgFor, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrackByPipe } from '@dougs/ds';
import { SynchronizationBannerListComponentService } from '../../services/synchronization-banner-list.component.service';
import { ConnectionErrorBannerComponent } from './connection-error-banner/connection-error-banner.component';
import { SynchronizedAccountMergeBannerComponent } from './synchronized-account-merge-banner/synchronized-account-merge-banner.component';

@Component({
  selector: 'dougs-synchronization-banner-list',
  templateUrl: './synchronization-banner-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    ConnectionErrorBannerComponent,
    SynchronizedAccountMergeBannerComponent,
    SlicePipe,
    TrackByPipe,
    AsyncPipe,
  ],
  providers: [SynchronizationBannerListComponentService],
})
export class SynchronizationBannerListComponent {
  @Input()
  showAll!: boolean;

  constructor(public readonly synchronizationBannerListComponentService: SynchronizationBannerListComponentService) {}
}
