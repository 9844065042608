<form
  (ngSubmit)="onSubmit()"
  [formGroup]="formGroup"
  class="mt-24"
  *ngIf="provider.connectorId !== 'bank:csv' && provider.connectorId !== 'bank:manual'"
>
  <div class="provider-form">
    <div>
      <label dougsFormFieldLabel>Date de récupération</label>
      <dougs-checkbox
        appearance="toggle"
        [ngModel]="isLock"
        (ngModelChange)="onLockChange($event)"
        [ngModelOptions]="{ standalone: true }"
        [showYesNo]="true"
        yesText="Verrouillé"
        noText="Déverrouillé"
      ></dougs-checkbox>
    </div>
    <div class="provider-form__date">
      <dougs-input-datepicker label="Min" formControlName="minDate"></dougs-input-datepicker>
      <dougs-input-datepicker label="Max" formControlName="maxDate"></dougs-input-datepicker>
    </div>
  </div>
  <div class="buttons" *ngIf="!isLock">
    <dougs-button color="secondary" (click)="cancelForm()">Annuler</dougs-button>
    <dougs-button type="submit">Enregistrer</dougs-button>
  </div>
</form>
<dougs-divider class="my-16"></dougs-divider>
<h5 class="mb-16">Synchronisation</h5>
<p class="small">
  <ng-container *ngIf="provider.connectorId !== 'bank:csv' && provider.connectorId !== 'bank:manual'">
    {{
      provider.transactionsDigestedAt
        ? 'Dernière le ' + (provider.transactionsDigestedAt | date: 'dd/MM/yyyy à HH:mm')
        : 'Synchronisation non effectuée'
    }}
  </ng-container>
  <ng-container *ngIf="provider.connectorId === 'bank:csv' || provider.connectorId === 'bank:manual'">
    {{
      provider.transactionsDigestedAt
        ? 'Effectuée le ' + (provider.transactionsDigestedAt | date: 'dd/MM/yyyy à HH:mm')
        : 'Non effectuée'
    }}
    <ng-container *ngIf="provider.data.importerName">
      {{ provider.transactionsDigestedAt ? ' par' : ', initiée par' }} {{ provider.data.importerName }}
    </ng-container>
  </ng-container>
</p>
<div class="mt-8 transactions-digest" *ngIf="provider.transactionsDigestedAt">
  <p class="small">
    <b>{{ provider.transactionsDigest?.result?.createdCount || 0 }}</b>
    transaction(s) créée(s)
  </p>
  <p class="small">
    <b>{{ provider.transactionsDigest?.result?.updatedCount || 0 }}</b>
    transaction(s) mise(s) à jour
  </p>
  <p class="small" [ngClass]="{ 'color-error': provider.transactionsDigest?.result?.errorCount }">
    <b>{{ provider.transactionsDigest?.result?.errorCount || 0 }}</b>
    erreur(s)
  </p>
</div>
<div class="mt-16 transactions-digest" *ngIf="connection?.status.code !== 'ok'">
  <p class="small" *ngIf="connection?.status.code">
    <b>Statut :</b>
    {{ connection?.status.code }}
  </p>
  <p class="small" *ngIf="connection?.status.originalCode?.length > 0">
    <b>Statut bis :</b>
    {{ connection.status.originalCode[0] }}
  </p>
  <p class="small" *ngIf="connection?.status.originalCode?.length > 1">
    <b>Statut Ter :</b>
    {{ connection.status.originalCode[1] }}
  </p>
</div>
<div class="mt-16" *ngIf="provider.connectorId !== 'bank:csv' && provider.connectorId !== 'bank:manual'">
  <dougs-divider class="mb-16"></dougs-divider>
  <h5 class="mb-16">{{ providerType }} ID</h5>
  <div class="provider-detail">
    <p class="small" *ngIf="connectorId">
      <b>Connection ID :</b>
      {{ connectorId }}
    </p>
    <p class="small" *ngIf="provider.externalId">
      <b>Account ID :</b>
      {{ provider.externalId }}
    </p>
  </div>
</div>
<div class="mt-16" *ngIf="provider.connectorId !== 'bank:csv' && provider.connectorId !== 'bank:manual'">
  <dougs-divider class="mb-16"></dougs-divider>
  <h5 class="mb-16">Détails</h5>
  <div class="provider-detail">
    <p class="small" *ngIf="provider.data?.iban">
      <b>IBAN :</b>
      {{ provider.data?.iban }}
    </p>
    <p class="small" *ngIf="provider.remoteSynchronizedAt">
      <b>remoteSynchronizedAt :</b>
      {{ provider.remoteSynchronizedAt | date: 'dd/MM/yyyy' }}
    </p>
    <p class="small" *ngIf="provider.id">
      <b>Provider ID :</b>
      {{ provider.id }}
    </p>
    <p class="small" *ngIf="provider.subType">
      <b>Subtype :</b>
      {{ subType }} ({{ provider.subType }})
    </p>
    <p class="small" *ngIf="provider.transactionsDigestedAt">
      <b>transactionsDigestedAt :</b>
      {{ provider.transactionsDigestedAt | date: 'dd/MM/yyyy' }}
    </p>
    <p class="small" *ngIf="provider.connectionId">
      <b>Connection ID :</b>
      {{ provider.connectionId }}
    </p>
    <p class="small" *ngIf="connection?.name">
      <b>Source :</b>
      {{ connection?.name }}
    </p>
    <p class="small" *ngIf="connection?.sourceId">
      <b>Source ID :</b>
      {{ connection?.sourceId }}
    </p>
  </div>
</div>
