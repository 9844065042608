<div class="settings-card__content">
  <div>
    <div class="settings-card__sticky-column-title">
      <h5>Comptes synchronisés</h5>
    </div>
  </div>
  <div>
    <div class="form-layout mb-16">
      <div class="synchronized-account-list" *ngIf="synchronizedAccounts?.length">
        <dougs-synchronized-account
          *ngFor="let synchronizedAccount of synchronizedAccounts; trackBy: trackById"
          [synchronizedAccount]="synchronizedAccount"
          [connections]="connections"
        ></dougs-synchronized-account>
      </div>
    </div>
    <dougs-synchronized-account-blank-slate
      *ngIf="synchronizedAccounts?.length === 0"
    ></dougs-synchronized-account-blank-slate>
    <div class="form-layout">
      <dougs-button
        [fullWidth]="true"
        color="primary-light"
        (click)="synchronizedAccountComponentService.openConnectionModal()"
      >
        <i class="fas fa-plus-circle color-primary mr-4"></i>
        Ajouter un compte
      </dougs-button>
    </div>
  </div>
</div>
