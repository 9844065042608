import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BlankStateComponent } from '@dougs/ds';

@Component({
  selector: 'dougs-synchronized-account-blank-slate',
  templateUrl: './synchronized-account-blank-slate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BlankStateComponent],
})
export class SynchronizedAccountBlankSlateComponent {}
