<dougs-panel-info class="mt-16" type="warning" *ngIf="askForBankStatements">
  <p class="small">
    Votre banque limite la synchronisation aux trois mois précédant votre inscription. Pour consulter toutes vos
    opérations, veuillez nous transmettre vos relevés bancaires.
  </p>
  <dougs-button class="mt-8" color="secondary" (click)="sendBankStatementToIntercom()">
    <i class="fal fa-plus mr-8"></i>
    Transmettre mes relevés bancaires
  </dougs-button>
</dougs-panel-info>
