import { Injectable } from '@angular/core';
import { Connection } from '@dougs/synchronized-accounts/dto';

export type Wording = { title: string; message: string; details: string; button: string };

@Injectable({
  providedIn: 'root',
})
export class SynchronizationBannerService {
  getBankName(connection: Connection): string | null {
    if (!connection.name) {
      return null;
    }

    const bankMap: { [key: string]: RegExp } = {
      'Crédit Agricole': new RegExp('^crédit agricole($| .*)', 'gmi'),
      'BNP Paribas': new RegExp('^bnp paribas($| .*)', 'gmi'),
      "Caisse d'Épargne": new RegExp("^caisse d'épargne($| .*)", 'gmi'),
      'Crédit Mutuel': new RegExp('^crédit mutuel($| .*)', 'gmi'),
      HSBC: new RegExp('^hsbc($| .*)', 'gmi'),
      LCL: new RegExp('^lcl($| .*)', 'gmi'),
    };

    for (const bank in bankMap) {
      if (connection.name.match(bankMap[bank])) {
        return bank;
      }
    }

    return connection.name;
  }

  getBankLogoURL(bankName: string | null): string {
    if (!bankName) {
      return `images/banks/Kevyn.jpg`;
    }

    const slugBankName = bankName.toLowerCase().replace(' ', '-');

    return `images/banks/${slugBankName}.jpg`;
  }
}
