import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, DividerComponent } from '@dougs/ds';
import { Wording } from '../../../services/connection-error-banner-component.service';

@Component({
  selector: 'dougs-synchronization-banner',
  templateUrl: './synchronization-banner.component.html',
  styleUrls: ['./synchronization-banner.component.scss'],
  standalone: true,
  imports: [DividerComponent, NgIf, ButtonComponent],
})
export class SynchronizationBannerComponent {
  @Input()
  wording!: Wording;
  @Input()
  image!: string;
  @Output()
  triggerActionEvent = new EventEmitter();

  triggerAction() {
    this.triggerActionEvent.emit();
  }
}
