import { AsyncPipe, CurrencyPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoaderComponent, PillComponent } from '@dougs/ds';
import { TreasuryStateService } from '@dougs/synchronized-accounts/shared';
import { SynchronizedAccountsTreasuryComponentService } from '../../services/synchronized-accounts-treasury.component.service';

@Component({
  selector: 'dougs-synchronized-accounts-treasury',
  templateUrl: './synchronized-accounts-treasury.component.html',
  styleUrls: ['./synchronized-accounts-treasury.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, LoaderComponent, PillComponent, CurrencyPipe, AsyncPipe],
  providers: [SynchronizedAccountsTreasuryComponentService],
})
export class SynchronizedAccountsTreasuryComponent {
  @Input() threeAccounts!: boolean;
  @Input() moreThreeAccounts!: boolean;

  constructor(
    public readonly synchronizedAccountsTreasuryComponentService: SynchronizedAccountsTreasuryComponentService,
    public readonly treasuryStateService: TreasuryStateService,
  ) {}
}
