import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';

@Component({
  selector: 'dougs-change-color-account-modal',
  templateUrl: './change-color-account-modal.component.html',
  styleUrls: ['./change-color-account-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, NgIf, LoaderComponent, NgFor, NgClass],
})
export class ChangeColorAccountModalComponent implements OnInit {
  availableColors: string[] | null = [];
  isLoading = true;
  isUpdateLoading = false;

  constructor(
    @Inject(MODAL_DATA) public synchronizedAccount: SynchronizedAccount,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
  ) {}

  async ngOnInit(): Promise<void> {
    this.availableColors = await this.synchronizedAccountStateService.getAvailableColors(this.synchronizedAccount);
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  async updateColor(color: string): Promise<void> {
    if (!this.isUpdateLoading) {
      this.isUpdateLoading = true;

      await this.synchronizedAccountStateService.updateAccount({
        ...this.synchronizedAccount,
        color,
      });
    }

    this.modalRef.close();
  }
}
