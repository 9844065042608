<ng-container *ngIf="synchronizedAccountsTreasuryComponentService.refreshTreasury$ | async" />
<div
  class="treasury-container"
  [ngClass]="{ 'three-accounts': threeAccounts, 'more-three-accounts': moreThreeAccounts }"
>
  <div class="treasury">
    <h5>Solde trésorerie</h5>
    <div
      class="skeleton title square mt-8"
      style="width: 200px"
      *ngIf="synchronizedAccountsTreasuryComponentService.isLoading$()"
    ></div>
    <h1 class="mt-8" *ngIf="!synchronizedAccountsTreasuryComponentService.isLoading$()">
      {{ (treasuryStateService.accountBalanceTreasury$ | async)?.totalTreasury | currency: 'EUR' }}
    </h1>
  </div>
  <div class="month-detail-container mt-8" *ngIf="synchronizedAccountsTreasuryComponentService.isLoading$()">
    <div class="month-detail mr-24">
      <div class="skeleton subtitle square mb-4" style="width: 80px"></div>
      <div class="skeleton subtitle ellipse" style="width: 80px"></div>
    </div>
    <div class="month-detail">
      <div class="skeleton subtitle square mb-4" style="width: 80px"></div>
      <div class="skeleton subtitle ellipse" style="width: 80px"></div>
    </div>
  </div>
  <div
    class="month-detail-container mt-8"
    [ngClass]="{ 'mt-16': moreThreeAccounts }"
    *ngIf="!synchronizedAccountsTreasuryComponentService.isLoading$()"
  >
    <div class="month-detail" [ngClass]="{ 'mb-8': !threeAccounts, 'mr-24': threeAccounts }">
      <p class="tiny" [ngClass]="{ 'mb-4': threeAccounts }">Entrées du mois</p>
      <dougs-pill type="success" [ngClass]="{ 'ml-8': !threeAccounts }">
        <i class="fal fa-arrow-up color-success mr-4"></i>
        +{{ (treasuryStateService.accountBalanceTreasury$ | async).monthlyIncomes | currency: 'EUR' }}
      </dougs-pill>
    </div>
    <div class="month-detail">
      <p class="tiny" [ngClass]="{ 'mb-4': threeAccounts }">Sorties du mois</p>
      <dougs-pill type="error" [ngClass]="{ 'ml-8': !threeAccounts }">
        <i class="fal fa-arrow-down color-error mr-4"></i>
        -{{ (treasuryStateService.accountBalanceTreasury$ | async).monthlyExpenses | currency: 'EUR' }}
      </dougs-pill>
    </div>
  </div>
</div>
