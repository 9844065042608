<dougs-divider></dougs-divider>
<div class="synchronization-banner px-64 py-16" *ngIf="!!wording?.title">
  <div class="synchronization-banner-left pr-16">
    <img
      [src]="image"
      [alt]="bankName"
      class="synchronization-banner-bank-logo"
      onerror="this.src='images/banks/Kevyn.jpg'"
    />
    <div class="divider"></div>
    <div class="synchronization-banner-status">
      <strong class="synchronization-banner-status-title">{{ wording.title }}</strong>
      <em class="synchronization-banner-status-message">{{ wording.message }}</em>
    </div>
  </div>
  <div class="synchronization-banner-right">
    <p class="synchronization-banner-details">{{ wording.details }}</p>
    <dougs-button
      class="synchronization-banner-button"
      color="warning"
      round="true"
      icon="tools"
      (click)="triggerAction()"
    >
      <span class="synchronization-banner-button-icon mr-4">
        <i class="fal fa-tools"></i>
      </span>
      {{ wording.button }}
    </dougs-button>
  </div>
</div>
