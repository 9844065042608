import { AsyncPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PillComponent,
} from '@dougs/ds';
import { Connection, SynchronizedAccount, TransactionProvider } from '@dougs/synchronized-accounts/dto';
import {
  ConnectionStateService,
  SUB_TYPES,
  SynchronizedAccountStateService,
} from '@dougs/synchronized-accounts/shared';
import { SynchronizedAccountComponentService } from '../../services/synchronized-account.component.service';
import { TransactionProviderComponent } from './transaction-provider/transaction-provider.component';

@Component({
  selector: 'dougs-transaction-providers-modal',
  templateUrl: './transaction-providers-modal.component.html',
  styleUrls: ['./transaction-providers-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    PillComponent,
    ModalCloseDirective,
    ModalContentDirective,
    NgFor,
    TransactionProviderComponent,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class TransactionProvidersModalComponent implements OnInit, OnDestroy {
  subType = '';
  showConnectors = true;
  showCSV = true;

  synchronizedAccount?: SynchronizedAccount;
  providers: TransactionProvider[] = [];
  filteredProviders: TransactionProvider[] = [];
  synchronizedAccountSubscription!: Subscription;

  constructor(
    @Inject(MODAL_DATA) public synchronizedAccountId: number,
    public synchronizedAccountStateService: SynchronizedAccountStateService,
    public synchronizedAccountComponentService: SynchronizedAccountComponentService,
    public connectionStateService: ConnectionStateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.synchronizedAccountSubscription = this.synchronizedAccountStateService.allSynchronizedAccounts$.subscribe(
      (synchronizedAccounts) => {
        this.synchronizedAccount = synchronizedAccounts.find(
          (synchronizedAccount) => synchronizedAccount.id === this.synchronizedAccountId,
        );
        this.subType = this.getSynchronizedAccountSubType();
        this.filteredProviders = this.providers = this.sortSynchronizedAccountProviders();
        this.cdr.markForCheck();
      },
    );
  }

  getSynchronizedAccountSubType(): string {
    return (
      (this.synchronizedAccount?.subType && SUB_TYPES[this.synchronizedAccount?.subType as keyof typeof SUB_TYPES]) ||
      ''
    );
  }

  sortSynchronizedAccountProviders(): TransactionProvider[] {
    return (
      this.synchronizedAccount?.providers.sort((a, b) => {
        const aDate = this.getProviderSortingDate(a);
        const bDate = this.getProviderSortingDate(b);

        if (aDate && bDate) {
          return bDate.getTime() - aDate.getTime();
        }

        if (aDate && !bDate) {
          return 1;
        }

        if (!aDate && bDate) {
          return -1;
        }

        if (b.connector?.type > a.connector?.type) {
          return -1;
        }

        if (a.connector?.type > b.connector?.type) {
          return 1;
        }

        return a.id - b.id;
      }) || []
    );
  }

  getConnectionFromProvider(connections: Connection[], provider: TransactionProvider): Connection | undefined {
    return connections?.find((connection) => connection.id === provider.connectionId);
  }

  filterProviders(): void {
    this.filteredProviders =
      this.synchronizedAccount?.providers.filter((provider) => {
        return (
          (this.showCSV && this.showConnectors) ||
          (this.showCSV && provider.connectorId === 'bank:csv') ||
          (this.showConnectors && provider.connectorId !== 'bank:csv')
        );
      }) || [];
  }

  async openConnectionModal(): Promise<void> {
    await lastValueFrom(
      this.synchronizedAccountComponentService.openConnectionModal(this.synchronizedAccount).afterClosed$,
    );

    if (this.synchronizedAccount) {
      await this.synchronizedAccountStateService.refreshSynchronizedAccount(this.synchronizedAccount);
    }
  }

  trackById(index: number, item: TransactionProvider): number {
    return item.id;
  }

  ngOnDestroy(): void {
    this.synchronizedAccountSubscription?.unsubscribe();
  }

  private getProviderSortingDate(provider: TransactionProvider): Date | undefined {
    if (['bank:csv', 'bank:manual'].includes(provider.connectorId)) {
      return new Date(provider.transactionsDigestedAt || 0);
    }

    if (provider.maxDate) {
      return new Date(provider.maxDate);
    }
  }
}
