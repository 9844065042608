import { CurrencyPipe, NgClass, NgIf, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { first, forkJoin, from, lastValueFrom, Subscription } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { CurrencyCodeToSymbolPipe, MemoComponent, PillComponent, TooltipDirective } from '@dougs/ds';
import {
  AccountingStatsStateService,
  NeedsAttentionOperationsStateService,
  NotValidatedOperationsStateService,
  ValidatedOperationsStateService,
} from '@dougs/operations/shared';
import { AccountBalance, Connection, Currency, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import {
  ConnectionStateService,
  SynchronizedAccountStateService,
  TreasuryStateService,
} from '@dougs/synchronized-accounts/shared';
import { UserStateService } from '@dougs/user/shared';

@Component({
  selector: 'dougs-synchronized-account-details',
  templateUrl: './synchronized-account-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, TooltipDirective, PillComponent, MemoComponent, FormsModule, SlicePipe, CurrencyPipe],
  providers: [CurrencyCodeToSymbolPipe],
})
export class SynchronizedAccountDetailsComponent implements OnInit, OnDestroy {
  accountConnectionsWithAction: (Connection | null)[] = [];
  @Input() synchronizedAccount!: SynchronizedAccount;
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();
  formControl: FormControl = new FormControl(true);
  formControlSubscription!: Subscription;

  constructor(
    private readonly treasuryStateService: TreasuryStateService,
    private readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
    private readonly validatedOperationsStateService: ValidatedOperationsStateService,
    private readonly needsAttentionOperationsStateService: NeedsAttentionOperationsStateService,
    private readonly accountingStatsStateService: AccountingStatsStateService,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly currencyCodeToSymbolPipe: CurrencyCodeToSymbolPipe,
    private readonly metricsService: MetricsService,
    public connectionStateService: ConnectionStateService,
    public userStateService: UserStateService,
    public companyStateService: CompanyStateService,
  ) {}

  private _hiddenAccountIds!: number[];

  get hiddenAccountIds() {
    return this._hiddenAccountIds;
  }

  @Input()
  set hiddenAccountIds(hiddenAccountIds: number[]) {
    if (this.synchronizedAccount?.id) {
      this.formControl.setValue(!hiddenAccountIds.includes(this.synchronizedAccount.id), { emitEvent: false });
    }
    this._hiddenAccountIds = hiddenAccountIds;
  }

  private _connectionsWithAction!: Connection[];

  get connectionsWithAction() {
    return this._connectionsWithAction;
  }

  @Input()
  set connectionsWithAction(connections: Connection[]) {
    this._connectionsWithAction = connections;

    this.getConnectionsWithActions();
  }

  ngOnInit(): void {
    this.formControlSubscription = this.formControl.valueChanges
      .pipe(
        tap(() => {
          this.isSearching.emit(true);
        }),
        map(() => this.synchronizedAccountStateService.getSelectedAccountIds(this.disableAccount())),
        tap((searchAccountIds: number[]) =>
          this.metricsService.pushMixpanelEvent('Accounting Operation Researched', {
            'search bank accounts': searchAccountIds.join(','),
          }),
        ),
        concatMap((searchAccountIds: number[]) =>
          forkJoin([
            from(
              this.notValidatedOperationsStateService.searchOperations(this.companyStateService.activeCompany.id, {
                accountIds: searchAccountIds,
              }),
            ),
            from(
              this.validatedOperationsStateService.searchOperations(this.companyStateService.activeCompany.id, {
                accountIds: searchAccountIds,
              }),
            ),
            from(
              this.needsAttentionOperationsStateService.searchOperations(this.companyStateService.activeCompany.id, {
                accountIds: searchAccountIds,
              }),
            ),
            from(
              this.accountingStatsStateService.refreshAccountingStats(this.companyStateService.activeCompany.id, {
                accountIds: searchAccountIds,
              }),
            ),
          ]),
        ),
      )
      .subscribe(() => this.isSearching.emit(false));
  }

  async onUpdateMemo(memo: string): Promise<void> {
    await this.synchronizedAccountStateService.updateAccountMemo(this.synchronizedAccount, memo);
  }

  getChangeRateTooltip(synchronizedAccount: SynchronizedAccount): string {
    const balance: AccountBalance = synchronizedAccount.metadata.balance;
    const originalCurrency: Currency = synchronizedAccount.metadata.balance.originalCurrency as Currency;
    return (
      `Solde estimé obtenu par la conversion du solde dans la devise originale ` +
      `(${balance.originalCurrencyBalance}${this.currencyCodeToSymbolPipe.transform(
        originalCurrency,
      )}) en euros au taux du ` +
      `jour 1€ = ${balance.changeRate?.toFixed(4)}${this.currencyCodeToSymbolPipe.transform(originalCurrency)}`
    );
  }

  async searchOnAccount(e: MouseEvent): Promise<void> {
    if (e.ctrlKey || e.shiftKey || e.metaKey) {
      await this.selectOnlyAccount();
    } else {
      this.formControl.setValue(!this.formControl.value);
    }
  }

  ngOnDestroy(): void {
    this.formControlSubscription.unsubscribe();
  }

  getConnectionsWithActions(): void {
    if (this.synchronizedAccount && this.connectionsWithAction) {
      this.accountConnectionsWithAction = this.connectionStateService.getConnectionsWithAction(
        this.connectionsWithAction,
        [this.synchronizedAccount],
      );
    }
  }

  private disableAccount(): number[] {
    const hiddenAccountIds: number[] = this.treasuryStateService.updateHiddenAccountIds(this.synchronizedAccount.id);
    this.treasuryStateService.updateAccountBalances();

    return hiddenAccountIds;
  }

  private async selectOnlyAccount(): Promise<void> {
    const allAccounts: SynchronizedAccount[] = await lastValueFrom(
      this.synchronizedAccountStateService.allSynchronizedAccounts$.pipe(first()),
    );
    this.treasuryStateService.setHiddenAccountIds(allAccounts.map((acc) => acc.id));
    this.formControl.setValue(true);
  }
}
