import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConnectionWithUserAction } from '@dougs/synchronized-accounts/dto';
import {
  ConnectionErrorBannerComponentService,
  Wording,
} from '../../../services/connection-error-banner-component.service';
import { SynchronizationBannerService } from '../../../services/synchronization-banner.service';
import { SynchronizationBannerComponent } from '../synchronization-banner/synchronization-banner.component';

@Component({
  selector: 'dougs-connection-error-banner',
  templateUrl: './connection-error-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConnectionErrorBannerComponentService],
  standalone: true,
  imports: [SynchronizationBannerComponent],
})
export class ConnectionErrorBannerComponent {
  private _connection!: ConnectionWithUserAction;
  wording!: Wording;
  bankLogo!: string;

  @Input()
  set connection(connection: ConnectionWithUserAction) {
    this._connection = connection;
    this.setBankDataAndWording(connection);
  }

  get connection() {
    return this._connection;
  }

  constructor(
    public readonly synchronizationBannerService: SynchronizationBannerService,
    public readonly connectionErrorBannerComponentService: ConnectionErrorBannerComponentService,
  ) {}

  setBankDataAndWording(connection: ConnectionWithUserAction) {
    const bankName = this.synchronizationBannerService.getBankName(connection);
    this.wording = this.connectionErrorBannerComponentService.getWording(connection.status.code, bankName);
    this.bankLogo = this.synchronizationBannerService.getBankLogoURL(bankName);
  }
}
