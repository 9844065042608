import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionWithUserAction } from '@dougs/synchronized-accounts/dto';
import { ConnectionStateService, SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { AccountMergeTask, Task } from '@dougs/task/dto';
import { UserTasksStateService } from '@dougs/task/shared';

@Injectable()
export class SynchronizationBannerListComponentService {
  synchronizedAccountMergeTasks$: Observable<AccountMergeTask[]> = this.userTasksStateService.tasks$.pipe(
    map((tasks) =>
      tasks ? (this.getUncompletedTasksByCode(tasks, ['customer:updateBankInformations']) as AccountMergeTask[]) : [],
    ),
  );

  connectionsWithAction$: Observable<ConnectionWithUserAction[]> = combineLatest([
    this.synchronizedAccountStateService.synchronizedAccounts$,
    this.connectionStateService.connections$,
  ]).pipe(
    map(([synchronizedAccounts, connections]) =>
      synchronizedAccounts && connections
        ? (this.connectionStateService.getConnectionsWithAction(
            connections,
            synchronizedAccounts,
          ) as ConnectionWithUserAction[])
        : [],
    ),
  );

  constructor(
    private readonly userTasksStateService: UserTasksStateService,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly connectionStateService: ConnectionStateService,
  ) {}

  getUncompletedTasksByCode(tasks: Task[], taskCode: string[]): Task[] {
    return tasks.filter((task) => taskCode.includes(task.code) && !task.completedAt);
  }
}
