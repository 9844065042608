<ng-container *ngIf="userStateService.loggedInUser$ | async as user">
  <div
    class="provider p-16 mb-8"
    [ngClass]="{ provider__deactivated: isCollapsed && isDeactivated, provider__expanded: !isCollapsed }"
  >
    <div class="provider__header">
      <div class="provider__header__left">
        <div class="provider__header__left__image">
          <img *ngIf="providerImage" [src]="'images/banks/' + providerImage" alt="Bank image" />
          <div class="provider__header__left__image__pill" [ngClass]="{ available: pillAvailable }"></div>
        </div>
        <h5
          class="ml-8"
          [ngClass]="{
            'color-success': connectionCode === 'OK',
            'color-warning': connectionCode === 'ACTION CLIENT',
            'color-error': connectionCode === 'ERREUR'
          }"
          *ngIf="!isDeactivated"
        >
          {{ connectionCode | uppercase }}
        </h5>
        <div class="ml-8" *ngIf="isDeactivated">
          <dougs-transation-provider-collapsed
            [provider]="provider"
            *ngIf="isCollapsed"
          ></dougs-transation-provider-collapsed>
        </div>
      </div>
      <div class="provider__header__right" *ngIf="user.isAccountantOrAdmin">
        <div [dougsDropdownTrigger]="exchangeDropdown" *ngIf="user.isAdmin" class="provider__header__right__button p-8">
          <i class="fal fa-exchange"></i>
        </div>
        <div [dougsDropdownTrigger]="synchronizeDropdown" class="provider__header__right__button p-8">
          <i class="fal fa-sync" [ngClass]="{ 'fa-spin': isSynchronize }"></i>
        </div>
        <div
          *ngIf="shouldShowDelete"
          (click)="synchronizedAccountComponentService.deleteProvider(provider)"
          class="provider__header__right__button p-8"
        >
          <i class="fal fa-trash"></i>
        </div>
        <div
          [dougsDropdownTrigger]="resetCredentials"
          *ngIf="!!connection && user.isAdmin && user.flags.includes('canResetConnectionCredentials')"
          class="provider__header__right__button p-8"
          (click)="confirmResetCredentials()"
        >
          <i class="fal fa-plug-circle-bolt"></i>
        </div>
      </div>
    </div>
    <div class="provider__body">
      <div class="ml-40" *ngIf="!isDeactivated">
        <p class="small mt-8" *ngIf="connection?.status.message">« {{ connection?.status.message }} »</p>
        <dougs-transation-provider-collapsed
          [provider]="provider"
          *ngIf="isCollapsed"
        ></dougs-transation-provider-collapsed>
      </div>
      <dougs-transation-provider-expanded
        [providerType]="providerType"
        [subType]="subType"
        [connection]="connection"
        [provider]="provider"
        *ngIf="!isCollapsed"
      ></dougs-transation-provider-expanded>
      <div class="file-list">
        <dougs-file-pill
          [canDelete]="false"
          *ngFor="let attachment of provider.attachments"
          [attachment]="attachment"
        ></dougs-file-pill>
      </div>
    </div>
    <div class="provider__footer" (click)="isCollapsed = !isCollapsed">
      <dougs-divider class="my-16"></dougs-divider>
      <i class="fal fa-chevron-down" [ngClass]="{ 'fa-chevron-up': !isCollapsed }"></i>
    </div>
  </div>
</ng-container>
<dougs-dropdown #exchangeDropdown [widthToRefElement]="false">
  <ng-container *ngFor="let account of synchronizedAccounts">
    <dougs-dropdown-option
      *ngIf="account.id !== provider.accountId"
      (click)="synchronizedAccountStateService.moveProvider(provider, account.id)"
      [closeOnClick]="true"
    >
      {{ account.accountingNumber }}
    </dougs-dropdown-option>
  </ng-container>
</dougs-dropdown>
<dougs-dropdown #synchronizeDropdown [widthToRefElement]="false">
  <dougs-dropdown-option [closeOnClick]="true" (click)="synchronizeProvider()">
    synchro rapide (recommandé)
  </dougs-dropdown-option>
  <dougs-dropdown-option [closeOnClick]="true" (click)="synchronizeRemoteConnection()">
    synchro avancée
  </dougs-dropdown-option>
</dougs-dropdown>
