<ng-container *ngIf="userStateService.loggedInUser$ | async as user">
  <ng-container *ngIf="formControlClosedChanges$ | async"></ng-container>
  <ng-container *ngIf="formControlHiddenChanges$ | async"></ng-container>
  <ng-container *ngIf="formControlMemoChanges$ | async"></ng-container>
  <div
    class="account-container py-8 pr-16"
    [ngClass]="[synchronizedAccount.closed ? 'disabled' : '', 'm-' + synchronizedAccount.color]"
  >
    <div class="account-detail-border pl-8">
      <h6 class="account-title pr-32">
        <span class="account-title__bank-name" [dougsTooltip]="synchronizedAccount.accountName">
          {{ synchronizedAccount.bankName }}
        </span>
        <span
          *ngIf="user.isAccountantOrAdmin && synchronizedAccount.subType === 'card'"
          class="color-admin account-number ml-8 mr-8"
        >
          <i class="fal fa-credit-card mr-4"></i>
        </span>
      </h6>
      <div>
        <dougs-pill class="mr-4" type="admin" *ngIf="user.isAccountantOrAdmin">
          {{ synchronizedAccount.accountingNumber }}
        </dougs-pill>
        <dougs-pill class="mr-4" *ngIf="synchronizedAccount.closed" type="error"> Clôturé </dougs-pill>
        <dougs-pill class="mr-4" *ngIf="synchronizedAccount.hidden" type="warning"> Caché </dougs-pill>
      </div>
      <dougs-memo (click)="$event.stopPropagation()" [light]="true" [formControl]="formControlMemo"></dougs-memo>
    </div>
    <div class="mt-4 account-container__account-name">
      <p class="tiny">
        {{ synchronizedAccount.accountName }} ({{ synchronizedAccount.currency }})
        <br />
        {{ synchronizedAccount.number }}
      </p>
    </div>
    <div *ngIf="!!connectionsWithAction?.length" class="mt-4 account-container__account-name">
      <p class="tiny color-warning">
        <i class="fal fa-exclamation-triangle"></i>
        La synchronisation est interrompue.
        <a class="color-warning check-connection" (click)="connectionService.checkConnections(connectionsWithAction)">
          Cliquez ici pour rétablir la connexion
        </a>
      </p>
    </div>
    <div
      *ngIf="!(companyDemoStateService.hasDemoMode$ | async) || user.isAccountantOrAdmin"
      class="account-actions"
      [dougsDropdownTrigger]="dropdownActions"
    >
      <i class="fal fa-ellipsis-h"></i>
    </div>
  </div>
  <dougs-dropdown #dropdownActions [widthToRefElement]="false">
    <dougs-dropdown-option *ngIf="user.isAdmin">
      <dougs-checkbox
        [formControl]="formControlClosed"
        appearance="toggle"
        [isAdmin]="true"
        [showYesNo]="true"
        noText="clôturé"
        yesText="actif"
      ></dougs-checkbox>
    </dougs-dropdown-option>
    <dougs-dropdown-option *ngIf="user.isAdmin && synchronizedAccount.closed">
      <dougs-checkbox
        [formControl]="formControlHidden"
        appearance="toggle"
        [isAdmin]="true"
        [showYesNo]="true"
        noText="caché"
        yesText="visible"
      ></dougs-checkbox>
    </dougs-dropdown-option>
    <dougs-dropdown-option
      [closeOnClick]="true"
      (click)="synchronizedAccountComponentService.openConnectionModal(synchronizedAccount)"
      *ngIf="user.isAccountantOrAdmin"
    >
      <span class="color-admin">Ajouter un provider</span>
    </dougs-dropdown-option>
    <dougs-dropdown-option
      [closeOnClick]="true"
      (click)="synchronizedAccountComponentService.openTransactionProviderModal(synchronizedAccount)"
      *ngIf="user.isAccountantOrAdmin"
    >
      <span class="color-admin">Afficher les providers</span>
    </dougs-dropdown-option>
    <dougs-dropdown-option
      [closeOnClick]="true"
      (click)="synchronizedAccountComponentService.openBankStatementsModal(synchronizedAccount)"
      *ngIf="user.isAccountantOrAdmin && shouldShowBankStatementsButton"
    >
      <span class="color-admin">Afficher les relevés</span>
    </dougs-dropdown-option>
    <dougs-dropdown-option
      [closeOnClick]="true"
      (click)="synchronizedAccountComponentService.openChangeColorAccountModal(synchronizedAccount)"
      *ngIf="user.isAccountantOrAdmin"
    >
      <span class="color-admin">Changer la couleur du compte</span>
    </dougs-dropdown-option>
    <dougs-dropdown-option
      [closeOnClick]="true"
      (click)="synchronizedAccountComponentService.deleteSynchronizedAccount(synchronizedAccount)"
    >
      <span class="color-error">Supprimer le compte</span>
    </dougs-dropdown-option>
  </dougs-dropdown>
</ng-container>
