import { Injectable } from '@angular/core';
import {
  CONNECTION_STATUS_CODE,
  ConnectionActionStatusCode,
  ConnectionWithUserAction,
} from '@dougs/synchronized-accounts/dto';
import { ConnectionService } from './connection.service';

export type Wording = { title: string; message: string; details: string; button: string };

@Injectable()
export class ConnectionErrorBannerComponentService {
  constructor(private readonly connectionService: ConnectionService) {}

  getWording(connectionStatusCode: ConnectionActionStatusCode, bankName: string | null = ''): Wording {
    const wordings = {
      [CONNECTION_STATUS_CODE.BANK_ACTION_NEEDED]: {
        message: 'Une action vous attend sur votre banque',
        details: `Votre banque ${bankName} a besoin de vous ! Allez compléter les informations demandées sur leur site pour rétablir la connexion, et revenez nous dire quand tout est bon !`,
        button: "C'est bon !",
      },
      [CONNECTION_STATUS_CODE.BANK_PASSWORD_ROTATION]: {
        message: 'Votre banque demande un nouveau mot de passe',
        details: `Rendez-vous sur le site de votre banque ${bankName} pour modifier votre mot de passe. Après avoir changé votre mot de passe sur le site de votre banque, mettez-le à jour sur votre espace Dougs pour rétablir la connexion !`,
        button: 'Mettre à jour',
      },
      [CONNECTION_STATUS_CODE.WRONGPASS]: {
        message: 'Vos identifiants ne sont plus à jour',
        details: `Vous avez changé vos identifiants bancaires ${bankName} sans les modifier sur Dougs et la synchronisation a été interrompue. Mettez à jour vos nouveaux identifiants bancaires sur votre espace Dougs pour rétablir la connexion !`,
        button: 'Mettre à jour',
      },
      [CONNECTION_STATUS_CODE.AUTHENTICATION_REQUIRED]: {
        message: 'Reconnectez Dougs à votre banque',
        details: `Pour une question de sécurité, votre banque ${bankName} vous demande de confirmer la connexion de votre compte à votre espace Dougs. Faites-le sans plus tarder : ça ne vous prendra que quelques secondes !`,
        button: 'Je me connecte',
      },
      [CONNECTION_STATUS_CODE.AUTHENTICATION_ACTION_NEEDED]: {
        message: 'Confirmez la connexion à votre banque',
        details: `Votre banque ${bankName} vous demande de confirmer la connexion de votre compte à votre espace Dougs. Faites-le sans plus tarder : c'est rapide et sécurisé !`,
        button: 'Confirmer',
      },
      [CONNECTION_STATUS_CODE.REQUEST_PROVIDER_FAILED]: {
        message: 'Reconnectez Dougs à votre banque',
        details: `Votre banque ${bankName} n'est plus synchronisée avec votre espace Dougs. Faites-le sans plus tarder : c'est rapide et sécurisé !`,
        button: 'Je me connecte',
      },
    };

    return { ...wordings[connectionStatusCode], title: 'On a perdu le contact !' };
  }

  async handleConnectionAction(connection: ConnectionWithUserAction) {
    await this.connectionService.handleConnectionStatus(connection);
  }
}
