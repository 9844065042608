import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { concatMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { TreasuryStateService } from '@dougs/synchronized-accounts/shared';

@Injectable()
export class SynchronizedAccountsTreasuryComponentService {
  private readonly isLoading: WritableSignal<boolean> = signal(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  refreshTreasury$ = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.isLoading.set(true)),
    concatMap((activeCompany) => this.treasuryStateService.refreshAccountBalances(activeCompany.id)),
    tap(() => this.isLoading.set(false)),
  );

  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly treasuryStateService: TreasuryStateService,
  ) {}
}
