import { AsyncPipe, NgClass, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ConfirmationModalComponent,
  DividerComponent,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  FilePillComponent,
  ModalService,
} from '@dougs/ds';
import {
  Connection,
  CONNECTION_STATUS_CODE,
  SynchronizedAccount,
  TransactionProvider,
} from '@dougs/synchronized-accounts/dto';
import {
  ConnectionStateService,
  PROVIDER_IMAGE,
  PROVIDER_TYPE,
  SourceStateService,
  SynchronizedAccountStateService,
} from '@dougs/synchronized-accounts/shared';
import { UserStateService } from '@dougs/user/shared';
import { SynchronizedAccountComponentService } from '../../../services/synchronized-account.component.service';
import { TransationProviderCollapsedComponent } from './transation-provider-collapsed/transation-provider-collapsed.component';
import { TransationProviderExpandedComponent } from './transation-provider-expanded/transation-provider-expanded.component';

@Component({
  selector: 'dougs-transaction-provider',
  templateUrl: './transaction-provider.component.html',
  styleUrls: ['./transaction-provider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    TransationProviderCollapsedComponent,
    DropdownTriggerDirective,
    TransationProviderExpandedComponent,
    NgFor,
    FilePillComponent,
    DividerComponent,
    DropdownComponent,
    DropdownOptionComponent,
    AsyncPipe,
    UpperCasePipe,
  ],
})
export class TransactionProviderComponent {
  private _provider!: TransactionProvider;
  private _synchronizedAccounts!: SynchronizedAccount[];

  providerImage!: string;
  providerType!: string;
  pillAvailable = false;
  connectionCode = '';
  shouldShowDelete = false;
  isDeactivated = false;
  isSynchronize = false;

  @Input() isCollapsed = true;
  @Input() subType = '';

  private _connection?: Connection;
  @Input()
  set connection(connection: Connection | undefined) {
    this._connection = connection;
    this.connectionCode = this.getConnectionCode();
  }

  get connection() {
    return this._connection;
  }

  @Input()
  set provider(provider: TransactionProvider) {
    this._provider = provider;

    this.providerImage = PROVIDER_IMAGE[provider.connectorId as keyof typeof PROVIDER_IMAGE] || '';
    this.providerType = PROVIDER_TYPE[provider.connectorId as keyof typeof PROVIDER_TYPE] || '';
    this.pillAvailable =
      provider.enabled && provider.connectorId !== 'bank:csv' && provider.connectorId !== 'bank:manual';
    this.isDeactivated = !(
      provider.enabled &&
      provider.connectorId !== 'bank:csv' &&
      provider.connectorId !== 'bank:manual'
    );
    this.shouldShowDelete =
      this.userStateService.loggedInUser.isAdmin ||
      (provider.connectorId !== 'bank:bi' && this.userStateService.loggedInUser.isAccountantOrAdmin);
    this.setConnectionCodeAndConnection();
  }

  get provider() {
    return this._provider;
  }

  @Input()
  set synchronizedAccounts(synchronizedAccounts: SynchronizedAccount[]) {
    this._synchronizedAccounts = synchronizedAccounts.sort((a, b) => a.accountingNumber - b.accountingNumber);
  }

  get synchronizedAccounts() {
    return this._synchronizedAccounts;
  }

  constructor(
    public userStateService: UserStateService,
    public synchronizedAccountStateService: SynchronizedAccountStateService,
    public synchronizedAccountComponentService: SynchronizedAccountComponentService,
    public connectionStateService: ConnectionStateService,
    public sourceStateService: SourceStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalService: ModalService,
  ) {}

  getConnectionCode(): string {
    switch (this.connection?.status.code) {
      case CONNECTION_STATUS_CODE.OK:
        return 'OK';
      case CONNECTION_STATUS_CODE.PENDING:
        return 'EN ATTENTE';
      case CONNECTION_STATUS_CODE.SYNCHRONIZATION_FAILED:
      case CONNECTION_STATUS_CODE.NOT_SUPPORTED:
      case CONNECTION_STATUS_CODE.RATE_LIMITING:
      case CONNECTION_STATUS_CODE.REQUEST_PROVIDER_FAILED:
      case CONNECTION_STATUS_CODE.UNKNOWN:
        return 'ERREUR';
      case CONNECTION_STATUS_CODE.BANK_ACTION_NEEDED:
      case CONNECTION_STATUS_CODE.BANK_PASSWORD_ROTATION:
      case CONNECTION_STATUS_CODE.WRONGPASS:
      case CONNECTION_STATUS_CODE.AUTHENTICATION_REQUIRED:
      case CONNECTION_STATUS_CODE.AUTHENTICATION_ACTION_NEEDED:
        return 'ACTION CLIENT';
      case undefined:
        return 'AUCUNE CONNEXION';
      default:
        return this.connection?.status.code || '';
    }
  }

  setConnectionCodeAndConnection() {
    this.connection = this.connectionStateService.getConnectionById(this.provider.connectionId);

    this.connectionCode = this.getConnectionCode();
  }

  async synchronizeProvider(): Promise<void> {
    if (!this.isSynchronize) {
      this.isSynchronize = true;
      await this.confirmProviderActivation();
      await this.synchronizedAccountStateService.synchronizeProvider(this.provider);
      if (this.connection) {
        await this.connectionStateService.refreshConnection(this.connection);
      }
      this.isSynchronize = false;
      this.cdr.markForCheck();
    }
  }

  async synchronizeRemoteConnection(): Promise<void> {
    if (!this.isSynchronize && this.connection) {
      this.isSynchronize = true;
      await this.confirmProviderActivation();
      await this.connectionStateService.synchronizeRemoteConnection(this.connection, true);
      await this.synchronizedAccountStateService.synchronizeProvider(this.provider);
      this.isSynchronize = false;
      this.cdr.markForCheck();
    }
  }

  async confirmProviderActivation(): Promise<void> {
    if (!this.provider.enabled && this.userStateService.loggedInUser.isAdmin) {
      const enableProvider = await lastValueFrom(
        this.modalService
          .open<boolean>(ConfirmationModalComponent, {
            data: {
              title: 'Activer le provider ?',
              body: `Souhaitez-vous activer ce provider pour synchroniser ses transactions ?
            Ses dates de synchronisation resteront inchangées.`,
              yesText: 'Activer le provider',
              noText: 'Seulement mettre à jour ses données',
            },
          })
          .afterClosed$.pipe(take(1)),
      );

      if (enableProvider.data) {
        await this.synchronizedAccountStateService.enableProvider(this.provider);
      }
    }
  }

  async confirmResetCredentials(): Promise<void> {
    if (this.connection) {
      const isConfirmed = (
        await lastValueFrom(
          this.modalService.open(ConfirmationModalComponent, {
            data: {
              title: 'Confirmation',
              body:
                'Êtes-vous sûr de vouloir réinitialiser les identifiants de cette connexion/provider ? ' +
                '<b>Attention</b>, pour Budget Insight et Bankin, cela va créer une tâche de fusion des comptes pour ' +
                'chaque compte synchronisé chez Dougs.',
              yesText: 'Confirmer la réinitialisation',
              noText: 'Annuler',
            },
          }).afterClosed$,
        )
      ).data;

      if (isConfirmed) {
        await this.connectionStateService.resetCredentials(this.connection);
      }
    }
  }
}
