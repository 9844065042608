<div class="demo-footer-text">
  <dougs-demo-mode-label></dougs-demo-mode-label>
  <p class="small">Les données présentées ici sont des exemples.</p>
</div>
<div class="demo-footer-buttons">
  <dougs-button
    [disabled]="accountingDemoFooterComponentService.shouldDisableRemoveDemoModeButton$ | async"
    (click)="accountingDemoFooterComponentService.deactivateDemoMode()"
    color="secondary"
    class="mr-8"
    >Enlever le compte démo</dougs-button
  >
  <dougs-button
    [disabled]="accountingDemoFooterComponentService.shouldDisableAddAccountButton$ | async"
    [dougsTooltip]="
      (accountingDemoFooterComponentService.isAccountingStateWaiting$ | async)
        ? 'Possible dès réception de votre Kbis'
        : ''
    "
    (click)="accountingDemoFooterComponentService.addAccount()"
    color="dark-primary"
  >
    <i class="mr-8 fa fa-sync"></i>
    Synchroniser votre compte bancaire
  </dougs-button>
</div>
