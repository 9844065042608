import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DougsDatePipe } from '@dougs/ds';
import { TransactionProvider } from '@dougs/synchronized-accounts/dto';

@Component({
  selector: 'dougs-transation-provider-collapsed',
  templateUrl: './transation-provider-collapsed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, DougsDatePipe],
})
export class TransationProviderCollapsedComponent {
  @Input() provider!: TransactionProvider;
}
