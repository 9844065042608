import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from '@dougs/ds';
import { Connection, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountComponentService } from '../services/synchronized-account.component.service';
import { SynchronizedAccountComponent } from './synchronized-account/synchronized-account.component';
import { SynchronizedAccountBlankSlateComponent } from './synchronized-account-blank-slate/synchronized-account-blank-slate.component';

@Component({
  selector: 'dougs-synchronized-accounts',
  templateUrl: './synchronized-accounts.component.html',
  styleUrls: ['./synchronized-accounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, SynchronizedAccountComponent, SynchronizedAccountBlankSlateComponent, ButtonComponent],
})
export class SynchronizedAccountsComponent {
  private _synchronizedAccounts: SynchronizedAccount[] = [];

  @Input()
  set synchronizedAccounts(synchronizedAccounts: SynchronizedAccount[]) {
    this._synchronizedAccounts = [...synchronizedAccounts]?.sort(
      (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
  }

  get synchronizedAccounts() {
    return this._synchronizedAccounts;
  }

  @Input() connections!: Connection[];

  constructor(public readonly synchronizedAccountComponentService: SynchronizedAccountComponentService) {}

  trackById(index: number, item: SynchronizedAccount): number {
    return item.id;
  }
}
