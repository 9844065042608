import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import { differenceInDays, parse } from 'date-fns';
import { CompanyStateService } from '@dougs/company/shared';
import { ButtonComponent, PanelInfoComponent } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { User } from '@dougs/user/dto';

@Component({
  selector: 'dougs-synchronization-limitation',
  templateUrl: './synchronization-limitation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, PanelInfoComponent, ButtonComponent],
})
export class SynchronizationLimitationComponent {
  private _operationsNotValidated: Operation[] = [];
  private _operationsValidated: Operation[] = [];
  activeUser!: User;

  askForBankStatements = false;

  @Input() operationNotValidatedCount!: number;
  @Input() operationValidatedCount!: number;
  @Input() hasCurrentSearch = false;

  @Input()
  set operationsNotValidated(operationsNotValidated: Operation[]) {
    this._operationsNotValidated = operationsNotValidated;
  }

  get operationsNotValidated() {
    return this._operationsNotValidated;
  }

  @Input()
  set operationsValidated(operationsValidated: Operation[]) {
    this._operationsValidated = operationsValidated;

    this.shouldAskForBankStatements();
  }

  get operationsValidated() {
    return this._operationsValidated;
  }

  constructor(
    private readonly intercom: Intercom,
    private readonly companyStateService: CompanyStateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  sendBankStatementToIntercom(): void {
    this.intercom.showNewMessage(
      'Bonjour, je souhaiterais vous transmettre mes relevés bancaires. Comment puis-je faire ?',
    );
  }

  shouldAskForBankStatements(): void {
    if (!this.companyStateService.activeCompany.accountingYear?.isFirst) {
      this.askForBankStatements = false;
    } else {
      if (
        !this.hasCurrentSearch &&
        this.operationValidatedCount === this.operationsValidated?.length &&
        this.operationNotValidatedCount === this.operationsNotValidated?.length
      ) {
        const lastNonValidated: Operation | undefined = this.operationsNotValidated?.slice(-1)?.pop();
        const lastValidated: Operation | undefined = this.operationsValidated?.slice(-1)?.pop();

        if (lastValidated || lastNonValidated) {
          const lastNonValidatedDate = new Date(lastNonValidated ? lastNonValidated.date : Date.now());
          const lastValidatedDate = new Date(lastValidated ? lastValidated.date : Date.now());
          const minDate = Math.min(lastNonValidatedDate.getTime(), lastValidatedDate.getTime());
          this.askForBankStatements =
            differenceInDays(
              minDate,
              parse(
                this.companyStateService.activeCompany.accountingYear.openingDate.toString(),
                'yyyy-MM-dd',
                new Date(),
              ).getTime(),
            ) > 90;
        }
      }
      this.askForBankStatements = false;
    }

    this.cdr.markForCheck();
  }
}
