<ng-container
  *ngFor="
    let connection of showAll
      ? (synchronizationBannerListComponentService.connectionsWithAction$ | async)
      : (synchronizationBannerListComponentService.connectionsWithAction$ | async | slice: 0 : 2);
    trackBy: 'id' | trackBy
  "
>
  <dougs-connection-error-banner [connection]="connection"></dougs-connection-error-banner>
</ng-container>
<ng-container
  *ngFor="
    let task of showAll
      ? (synchronizationBannerListComponentService.synchronizedAccountMergeTasks$ | async)
      : (synchronizationBannerListComponentService.synchronizedAccountMergeTasks$ | async | slice: 0 : 2);
    trackBy: 'id' | trackBy
  "
>
  <dougs-synchronized-account-merge-banner [task]="task"></dougs-synchronized-account-merge-banner>
</ng-container>
