import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AccountMergeTask } from '@dougs/task/dto';
import {
  SynchronizedAccountMergeBannerComponentService,
  Wording,
} from '../../../services/synchronized-account-merge-banner-component.service';
import { SynchronizationBannerComponent } from '../synchronization-banner/synchronization-banner.component';

@Component({
  selector: 'dougs-synchronized-account-merge-banner',
  templateUrl: './synchronized-account-merge-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SynchronizedAccountMergeBannerComponentService],
  standalone: true,
  imports: [SynchronizationBannerComponent, AsyncPipe],
})
export class SynchronizedAccountMergeBannerComponent {
  private _task!: AccountMergeTask;
  wording!: Wording;

  @Input()
  set task(task: AccountMergeTask) {
    this._task = task;
    if (task.metadata.mergeData.mergeReason) {
      this.synchronizedAccountMergeBannerService.connectionId = task.metadata.mergeData.connectionId;
      this.wording = this.synchronizedAccountMergeBannerService.getWording(task);
    }
  }

  get task(): AccountMergeTask {
    return this._task;
  }

  constructor(public readonly synchronizedAccountMergeBannerService: SynchronizedAccountMergeBannerComponentService) {}

  trackById(index: number, task: AccountMergeTask): number {
    return task.id;
  }
}
