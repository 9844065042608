import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetricsEvent, MetricsService } from '@dougs/core/metrics';
import { ModalService } from '@dougs/ds';
import { SERVICE_STATE } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { DemoModeService, SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';

@Injectable()
export class DemoModeFooterComponentService {
  constructor(
    private readonly demoModeService: DemoModeService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly modalService: ModalService,
    private readonly metricsService: MetricsService,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
  ) {}

  private readonly _isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this._isLoadingSubject.asObservable();

  isAccountingStateWaiting$: Observable<boolean> = this.companyServicesStateService.services$.pipe(
    map((services) => services.accounting.state === SERVICE_STATE.WAITING),
  );
  shouldDisableAddAccountButton$: Observable<boolean> = combineLatest([
    this.isLoading$,
    this.isAccountingStateWaiting$,
    this.synchronizedAccountStateService.synchronizingAccounts$,
  ]).pipe(
    map(
      ([isLoading, isAccountingStateWaiting, synchronizingAccounts]) =>
        isLoading || isAccountingStateWaiting || synchronizingAccounts.length > 0,
    ),
  );

  shouldDisableRemoveDemoModeButton$: Observable<boolean> = combineLatest([
    this.isLoading$,
    this.synchronizedAccountStateService.synchronizingAccounts$,
  ]).pipe(map(([isLoading, synchronizingAccounts]) => isLoading || synchronizingAccounts.length > 0));

  async addAccount(): Promise<void> {
    const ctaEvent: MetricsEvent = {
      action: `CTA Clicked`,
      category: `Accounting Demo Bank Synchronization`,
    };
    this.metricsService.pushGAEvent(ctaEvent);
    this.metricsService.pushMixpanelEvent(ctaEvent);

    await this.deactivateDemoMode();

    const { AddConnectionModalComponent } = await import(
      '../modals/add-connection-modal/add-connection-modal.component'
    );
    await lastValueFrom(
      this.modalService.open(AddConnectionModalComponent, {
        data: { metadata: { location: 'Accounting Demo Mode On' } },
      }).afterClosed$,
    );
  }

  async deactivateDemoMode(): Promise<void> {
    this._isLoadingSubject.next(true);
    await this.demoModeService.deactivateDemoMode();
    this._isLoadingSubject.next(false);

    const ctaEvent: MetricsEvent = {
      action: `CTA Removed`,
      category: `Accounting Demo Account`,
    };
    this.metricsService.pushGAEvent(ctaEvent);
    this.metricsService.pushMixpanelEvent(ctaEvent);
  }
}
