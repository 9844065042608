<p class="tiny color-gray-350 mt-8">
  <ng-container *ngIf="provider.transactionsDigestedAt">
    Synchronisation le {{ provider.transactionsDigestedAt | date: 'dd/MM/yyyy' }} •
    <b>{{ provider.transactionsDigest?.result?.createdCount || 0 }}</b>
    transaction(s) créée(s)
    <ng-container *ngIf="provider.transactionsDigest?.result?.errorCount">
      •
      <span class="color-error">
        <b>{{ provider.transactionsDigest?.result?.errorCount }}</b>
        erreur(s)
      </span>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!provider.transactionsDigestedAt">Synchronisation non effectuée</ng-container>
</p>
<p class="tiny color-gray-350">
  <ng-container *ngIf="provider.connectorId !== 'bank:csv' && provider.connectorId !== 'bank:manual'">
    <ng-container *ngIf="provider.minDate && provider.maxDate">
      Récupération du
      <b>{{ provider.minDate | date: 'dd/MM/yyyy' }}</b>
      au
      <b>{{ provider.maxDate | date: 'dd/MM/yyyy' }}</b>
    </ng-container>
    <ng-container *ngIf="provider.minDate && !provider.maxDate">
      Récupération depuis le
      <b>{{ provider.minDate | date: 'dd/MM/yyyy' }}</b>
    </ng-container>
    <ng-container *ngIf="!provider.minDate && provider.maxDate">
      Récupération jusqu'au
      <b>{{ provider.maxDate | date: 'dd/MM/yyyy' }}</b>
    </ng-container>
    <ng-container *ngIf="!provider.minDate && !provider.maxDate">
      Récupération
      <b>indéterminée</b>
    </ng-container>
  </ng-container>
</p>
<p class="tiny color-gray-350" *ngIf="provider.connectorId === 'bank:csv' && provider.data?.importerName">
  {{ provider.transactionsDigestedAt ? 'Effectuée' : 'Initiée' }} par
  <b>{{ provider.data?.importerName }}</b>
</p>
