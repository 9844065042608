<div dougsModalTitle>
  <h6>
    Providers {{ subType }}
    <dougs-pill
      (click)="showConnectors = !showConnectors; filterProviders()"
      class="ml-16"
      [type]="showConnectors ? 'secondary' : 'primary'"
    >
      Connecteurs
    </dougs-pill>
    <dougs-pill (click)="showCSV = !showCSV; filterProviders()" class="ml-8" [type]="showCSV ? 'secondary' : 'primary'">
      CSV
    </dougs-pill>
  </h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-transaction-provider
    [subType]="subType"
    [connection]="getConnectionFromProvider(connectionStateService.connections$ | async, provider)"
    [isCollapsed]="providers.length > 1"
    [synchronizedAccounts]="synchronizedAccountStateService.synchronizedAccounts$ | async"
    *ngFor="let provider of filteredProviders; trackBy: trackById"
    [provider]="provider"
  ></dougs-transaction-provider>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" type="button" dougsModalClose>Fermer</dougs-button>
  <dougs-button type="button" (click)="openConnectionModal()">Ajouter un provider</dougs-button>
</div>
