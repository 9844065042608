import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MetricsService } from '@dougs/core/metrics';
import { ConfirmationModalComponent, ModalRef, ModalService } from '@dougs/ds';
import { SynchronizedAccount, TransactionProvider } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { AddConnectionModalComponent } from '../modals/add-connection-modal/add-connection-modal.component';
import { BankStatementsModalComponent } from '../modals/bank-statements-modal/bank-statements-modal.component';
import { ChangeColorAccountModalComponent } from '../modals/change-color-account-modal/change-color-account-modal.component';
import { TransactionProvidersModalComponent } from '../modals/transaction-providers-modal/transaction-providers-modal.component';

@Injectable({
  providedIn: 'root',
})
export class SynchronizedAccountComponentService {
  constructor(
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly modalService: ModalService,
    private readonly metricsService: MetricsService,
  ) {}

  async deleteSynchronizedAccount(synchronizedAccount: SynchronizedAccount): Promise<void> {
    const isConfirmed = (
      await lastValueFrom(
        this.modalService.open(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body:
              `Êtes-vous sûr de vouloir supprimer votre compte <b>${synchronizedAccount.accountName}</b> ` +
              `de la banque <b>${synchronizedAccount.bankName}</b>&nbsp;? ` +
              'Toutes les opérations attachées à ce compte seront supprimées. Cette action est <b>définitive</b>.',
            yesText: 'Oui, supprimer mon compte et les opérations',
            noText: 'Non, conserver mon compte',
          },
        }).afterClosed$,
      )
    ).data;

    if (isConfirmed) {
      await this.synchronizedAccountStateService.deleteAccount(synchronizedAccount);
    }
  }

  async deleteProvider(provider: TransactionProvider): Promise<void> {
    const isConfirmed = (
      await lastValueFrom(
        this.modalService.open(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: 'Êtes-vous sûr de vouloir supprimer cette source ? Cette action est <b>définitive</b>.',
            yesText: 'Oui, supprimer cette source et toutes ses opérations',
            noText: 'Non',
          },
        }).afterClosed$,
      )
    ).data;

    if (isConfirmed) {
      await this.synchronizedAccountStateService.deleteProvider(provider);
    }
  }

  openConnectionModal(synchronizedAccount?: SynchronizedAccount): ModalRef {
    this.metricsService.pushMixpanelEvent('Accounting Bank Synchronization CTA Clicked', {
      source: 'settings',
    });

    return this.modalService.open(AddConnectionModalComponent, {
      data: {
        synchronizedAccount,
        metadata: {
          location: 'Settings',
        },
      },
      disableClose: true,
    });
  }

  openChangeColorAccountModal(synchronizedAccount: SynchronizedAccount): void {
    this.modalService.open(ChangeColorAccountModalComponent, {
      data: synchronizedAccount,
    });
  }

  openBankStatementsModal(synchronizedAccount: SynchronizedAccount): void {
    this.modalService.open(BankStatementsModalComponent, {
      data: synchronizedAccount.id,
    });
  }

  openTransactionProviderModal(synchronizedAccount: SynchronizedAccount): void {
    this.modalService.open(TransactionProvidersModalComponent, {
      data: synchronizedAccount.id,
    });
  }
}
