import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  ButtonComponent,
  CheckboxComponent,
  DividerComponent,
  DougsDatePipe,
  InputDatepickerComponent,
  LabelFormFieldDirective,
} from '@dougs/ds';
import { Connection, TransactionProvider } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';

@Component({
  selector: 'dougs-transation-provider-expanded',
  templateUrl: './transation-provider-expanded.component.html',
  styleUrls: ['./transation-provider-expanded.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    LabelFormFieldDirective,
    CheckboxComponent,
    InputDatepickerComponent,
    ButtonComponent,
    DividerComponent,
    NgClass,
    DougsDatePipe,
  ],
})
export class TransationProviderExpandedComponent {
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    minDate: new UntypedFormControl({ value: null, disabled: true }),
    maxDate: new UntypedFormControl({ value: null, disabled: true }),
  });

  isLock = true;
  connectorId?: number;

  private _connection!: Connection;

  @Input()
  set connection(connection: Connection) {
    this._connection = connection;

    if (connection?.connectorId === 'bank:bankin') {
      this.connectorId = connection?.data?.bankinItemId;
    }

    if (connection?.connectorId === 'bank:bi') {
      this.connectorId = connection?.data?.biConnectionId;
    }
  }

  get connection() {
    return this._connection;
  }

  @Input() subType = '';
  @Input() providerType = '';

  private _provider!: TransactionProvider;
  @Input()
  set provider(provider: TransactionProvider) {
    this._provider = provider;
    this.formGroup.get('minDate')?.setValue(provider.minDate || null);
    this.formGroup.get('maxDate')?.setValue(provider.maxDate || null);
  }

  get provider() {
    return this._provider;
  }

  constructor(private readonly synchronizedAccountStateService: SynchronizedAccountStateService) {}

  onLockChange(isLock: boolean): void {
    this.isLock = isLock;

    if (isLock) {
      this.formGroup.get('minDate')?.disable({ emitEvent: false });
      this.formGroup.get('maxDate')?.disable({ emitEvent: false });
    } else {
      this.formGroup.get('minDate')?.enable({ emitEvent: false });
      this.formGroup.get('maxDate')?.enable({ emitEvent: false });
    }
  }

  cancelForm(): void {
    this.formGroup.reset();
    this.formGroup.markAsPristine();
    this.isLock = true;
  }

  async onSubmit(): Promise<void> {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.invalid) {
      await this.synchronizedAccountStateService.updateProvider({
        ...this.provider,
        ...this.formGroup.value,
      });

      this.onLockChange(true);
    }
  }
}
