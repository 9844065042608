import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { CompanyDemoStateService } from '@dougs/company/shared';
import {
  CheckboxComponent,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  MemoComponent,
  PillComponent,
  TooltipDirective,
} from '@dougs/ds';
import { Connection, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { ConnectionStateService, SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { UserStateService } from '@dougs/user/shared';
import { ConnectionService } from '../../services/connection.service';
import { SynchronizedAccountComponentService } from '../../services/synchronized-account.component.service';

@Component({
  selector: 'dougs-synchronized-account',
  templateUrl: './synchronized-account.component.html',
  styleUrls: ['./synchronized-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    TooltipDirective,
    PillComponent,
    MemoComponent,
    FormsModule,
    ReactiveFormsModule,
    DropdownTriggerDirective,
    DropdownComponent,
    DropdownOptionComponent,
    CheckboxComponent,
    AsyncPipe,
  ],
})
export class SynchronizedAccountComponent {
  private _synchronizedAccount!: SynchronizedAccount;
  private _connections!: Connection[];

  shouldShowBankStatementsButton = false;
  connectionsWithAction: (Connection | null)[] = [];

  @Input()
  set connections(connections: Connection[]) {
    this._connections = connections;

    this.getConnectionsWithActions();
  }

  get connections() {
    return this._connections;
  }

  @Input()
  set synchronizedAccount(synchronizedAccount: SynchronizedAccount) {
    this._synchronizedAccount = synchronizedAccount;

    this.formControlClosed.setValue(!synchronizedAccount.closed, { emitEvent: false });
    this.formControlHidden.setValue(!synchronizedAccount.hidden, { emitEvent: false });
    this.formControlMemo.setValue(synchronizedAccount.memo, { emitEvent: false });

    this.shouldShowBankStatementsButton = !!synchronizedAccount.metadata.balances?.length;

    this.getConnectionsWithActions();
  }

  get synchronizedAccount() {
    return this._synchronizedAccount;
  }

  formControlMemo: UntypedFormControl = new UntypedFormControl('');
  formControlMemoChanges$: Observable<unknown> = this.formControlMemo.valueChanges.pipe(
    concatMap((value) => from(this.synchronizedAccountStateService.updateAccountMemo(this.synchronizedAccount, value))),
  );

  formControlClosed: UntypedFormControl = new UntypedFormControl('');
  formControlClosedChanges$: Observable<unknown> = this.formControlClosed.valueChanges.pipe(
    concatMap((value) =>
      from(
        this.synchronizedAccountStateService.updateAccount({
          ...this.synchronizedAccount,
          closed: !value,
        }),
      ),
    ),
  );

  formControlHidden: UntypedFormControl = new UntypedFormControl('');
  formControlHiddenChanges$: Observable<unknown> = this.formControlHidden.valueChanges.pipe(
    concatMap((value) =>
      from(
        this.synchronizedAccountStateService.updateAccount({
          ...this.synchronizedAccount,
          hidden: !value,
        }),
      ),
    ),
  );

  constructor(
    public readonly userStateService: UserStateService,
    public readonly synchronizedAccountComponentService: SynchronizedAccountComponentService,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    public readonly connectionStateService: ConnectionStateService,
    public readonly connectionService: ConnectionService,
    public readonly companyDemoStateService: CompanyDemoStateService,
  ) {}

  getConnectionsWithActions(): void {
    if (this.synchronizedAccount && this.connections) {
      this.connectionsWithAction = this.connectionStateService.getConnectionsWithAction(this.connections, [
        this.synchronizedAccount,
      ]);
    }
  }
}
