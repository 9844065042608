import { Injectable } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionStateService } from '@dougs/synchronized-accounts/shared';
import { AccountMergeTask } from '@dougs/task/dto';
import { AccountMergeTaskService } from './account-merge-task.service';
import { SynchronizationBannerService } from './synchronization-banner.service';

export type Wording = { title: string; message: string; details: string; button: string };

@Injectable()
export class SynchronizedAccountMergeBannerComponentService {
  private readonly connectionId$: Subject<number> = new Subject<number>();

  private readonly retrieveConnection$ = combineLatest([
    this.connectionStateService.connections$,
    this.connectionId$.asObservable(),
  ]).pipe(map(([connections, connectionId]) => connections.find((connection) => connection.id === connectionId)));

  bankLogo$ = this.retrieveConnection$.pipe(
    map((connection) => {
      if (connection) {
        return this.synchronizationBannerService.getBankLogoURL(
          this.synchronizationBannerService.getBankName(connection),
        );
      }
    }),
  );

  constructor(
    private readonly accountMergeTaskService: AccountMergeTaskService,
    private readonly synchronizationBannerService: SynchronizationBannerService,
    private readonly connectionStateService: ConnectionStateService,
  ) {}

  set connectionId(connectionId: number) {
    this.connectionId$.next(connectionId);
  }

  getWording(task: AccountMergeTask): Wording {
    const wordings: { [key in 'upgrade' | 'notFound' | 'cardExpired']: Omit<Wording, 'details' | 'button'> } = {
      upgrade: {
        title: 'Nouvelle connexion',
        message: 'Une meilleure expérience',
      },
      notFound: {
        title: 'On a perdu le contact !',
        message: 'Reconnectez Dougs à votre banque',
      },
      cardExpired: {
        title: "Votre carte vient d'expirer",
        message: 'Faites-nous voir la nouvelle !',
      },
    };

    return {
      ...wordings[task.metadata.mergeData.mergeReason as 'upgrade' | 'notFound' | 'cardExpired'],
      details: task.title,
      button: this.accountMergeTaskService.getActionWording(task),
    };
  }

  async openMergeModal(task: AccountMergeTask): Promise<void> {
    return this.accountMergeTaskService.handleAccountMergeTask(task);
  }
}
