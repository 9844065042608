<div dougsModalTitle>
  <h6 class="mr-16">
    {{ synchronizedAccount?.name }}
    <i [ngClass]="{ 'fa-spin': isRefreshing }" class="fal fa-sync ml-8 fa-lg" (click)="updateBalances()"></i>
  </h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-blank-state *ngIf="filteredBalances.length === 0">
    <p class="mt-8">Aucun relevé</p>
  </dougs-blank-state>
  <div class="table" *ngIf="filteredBalances.length > 0">
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Solde fourni par le client</th>
          <th>
            Solde dans Dougs
            <span class="toggle-include-date" (click)="includeDate = !includeDate">
              {{ includeDate ? 'date incluse' : 'date exclue' }}
            </span>
          </th>
          <th>Écart</th>
          <th>Relevés de comptes</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let balance of filteredBalances; trackBy: trackById">
          <td>{{ balance.date | date: 'dd/MM/yyyy' }}</td>
          <td>{{ balance.balance }}</td>
          <td>
            {{ includeDate ? balance.endOfDayBalance : balance.startOfDayBalance }}
          </td>
          <td>
            {{
              includeDate
                ? calculateDiff(balance.balance, balance.endOfDayBalance)
                : calculateDiff(balance.balance, balance.startOfDayBalance)
            }}
          </td>
          <td>
            <div class="file-list">
              <dougs-file-pill
                *ngIf="getBankStatement(balance)"
                [attachment]="getBankStatement(balance)"
                [canDelete]="false"
              ></dougs-file-pill>
            </div>
          </td>
          <td (click)="removeBalance(balance)">
            <i class="fal fa-times color-error fa-lg"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
