import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { FlashMessagesService, ModalService } from '@dougs/ds';
import { AccountMergeTask, Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService, UserTasksStateService } from '@dougs/task/shared';
import { AddConnectionModalComponent } from '../modals/add-connection-modal/add-connection-modal.component';

@Injectable({
  providedIn: 'root',
})
export class AccountMergeTaskService {
  constructor(
    private readonly modalService: ModalService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly companyStateService: CompanyStateService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
  ) {}

  getActionWording(task: AccountMergeTask): string {
    switch (task.metadata.mergeData.mergeReason) {
      case 'upgrade':
        return 'Je fonce !';
      case 'notFound':
        return 'Je me connecte';
      case 'cardExpired':
      default:
        return 'Mettre à jour';
    }
  }

  async handleAccountMergeTask(task: AccountMergeTask): Promise<void> {
    await lastValueFrom(
      this.modalService.open(AddConnectionModalComponent, {
        data: { mergeData: task.metadata.mergeData },
        disableClose: true,
      }).afterClosed$,
    );

    const updatedTask: Task | null = await this.controlPanelTasksStateService.getTask(task);

    if (updatedTask?.completedAt) {
      switch (updatedTask.completionReasonCode) {
        case 'succeed':
          this.flashMessagesService.show('Vos comptes ont été mis à jour avec succès !', {
            type: 'success',
            timeout: 5000,
          });
          break;
        case 'partiallySucceed':
          this.flashMessagesService.show(
            "Certains de vos comptes n'ont pas pu être mis à jour. Le support a été prévenu et s'occupe de votre dossier.",
            {
              type: 'warning',
              timeout: 5000,
            },
          );
          break;
        case 'failed':
          this.flashMessagesService.show(
            "Vos comptes n'ont pas pu être mis à jour. Le support a été prévenu et s'occupe de votre dossier.",
            {
              type: 'error',
              timeout: 5000,
            },
          );
      }

      await this.userTasksStateService.refreshTasks(this.companyStateService.activeCompany);
    }
  }
}
