import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent, TooltipDirective } from '@dougs/ds';
import { DemoModeFooterComponentService } from '../../services/demo-mode-footer.component.service';
import { DemoModeLabelComponent } from '../demo-mode-label/demo-mode-label.component';

@Component({
  selector: 'dougs-demo-mode-footer',
  standalone: true,
  imports: [CommonModule, ButtonComponent, DemoModeLabelComponent, TooltipDirective],
  providers: [DemoModeFooterComponentService],
  templateUrl: './demo-mode-footer.component.html',
  styleUrls: ['./demo-mode-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoModeFooterComponent {
  constructor(public readonly accountingDemoFooterComponentService: DemoModeFooterComponentService) {}
}
