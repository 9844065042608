<div
  class="account-container py-8 pr-16"
  (click)="searchOnAccount($event)"
  [ngClass]="[hiddenAccountIds?.includes(synchronizedAccount.id) ? 'disabled' : '', 'm-' + synchronizedAccount.color]"
>
  <div class="account-detail-border pl-8">
    <h6 class="account-title">
      <i
        class="fal fa-exclamation-triangle color-warning mr-8"
        [dougsTooltip]="'La synchronisation avec cette banque est interrompue, une action de votre part est requise.'"
        *ngIf="!!accountConnectionsWithAction.length"
      ></i>
      <span class="account-title__bank-name" [dougsTooltip]="synchronizedAccount.accountName">
        {{ synchronizedAccount.bankName }}
      </span>
      <ng-container *ngIf="userStateService.loggedInUser.isAccountantOrAdmin">
        <span class="color-admin account-number ml-8 mr-8">
          <i class="fal fa-credit-card mr-4" *ngIf="synchronizedAccount.subType === 'card'"></i>
          <span>{{ synchronizedAccount.number | slice: -5 }}</span>
        </span>
        <dougs-pill class="ml-auto" type="admin">
          {{ synchronizedAccount.accountingNumber }}
        </dougs-pill>
      </ng-container>
    </h6>
    <dougs-memo
      (click)="$event.stopPropagation()"
      [light]="true"
      [ngModel]="synchronizedAccount.memo"
      (ngModelChange)="onUpdateMemo($event)"
    ></dougs-memo>
  </div>
  <div
    *ngIf="!synchronizedAccount.closed; else closedAccount"
    class="account-balance-container pl-8"
    [ngClass]="{ 'mt-4': synchronizedAccount.metadata?.balance?.balance !== null }"
  >
    <i
      *ngIf="synchronizedAccount.metadata?.balance?.changeRate"
      class="fal fa-question-circle mr-4"
      [dougsTooltip]="getChangeRateTooltip(synchronizedAccount)"
    ></i>
    <h6>{{ synchronizedAccount.metadata?.balance?.balance || 0 | currency: 'EUR' }}</h6>
  </div>
  <ng-template #closedAccount>
    <p class="mt-4 account-balance-container pl-8 tiny">Compte clôturé</p>
  </ng-template>
</div>
