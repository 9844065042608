<div dougsModalTitle>
  <h6>Changer la couleur du compte</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-loader *ngIf="isLoading"></dougs-loader>
  <div class="available-colors">
    <div
      (click)="updateColor(color)"
      *ngFor="let color of availableColors"
      [ngClass]="'m-' + color"
      class="available-colors__color"
    ></div>
  </div>
</div>
