import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Attachment } from '@dougs/core/files';
import {
  BlankStateComponent,
  DougsDatePipe,
  FilePillComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { AccountBalances, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';

@Component({
  selector: 'dougs-bank-statements-modal',
  templateUrl: './bank-statements-modal.component.html',
  styleUrls: ['./bank-statements-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    NgClass,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    BlankStateComponent,
    NgFor,
    FilePillComponent,
    DougsDatePipe,
  ],
})
export class BankStatementsModalComponent implements OnInit, OnDestroy {
  includeDate = true;
  isRemoving = false;
  isRefreshing = false;
  synchronizedAccount!: SynchronizedAccount | undefined;
  filteredBalances: AccountBalances[] = [];
  synchronizedAccountSubscription!: Subscription;

  constructor(
    @Inject(MODAL_DATA) public synchronizedAccountId: number,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.synchronizedAccountSubscription = this.synchronizedAccountStateService.synchronizedAccounts$.subscribe(
      (synchronizedAccounts) => {
        this.synchronizedAccount = synchronizedAccounts.find((account) => account.id === this.synchronizedAccountId);
        this.filteredBalances =
          this.synchronizedAccount?.metadata.balances?.filter((balance) => balance.validated) || [];

        this.cdr.markForCheck();
      },
    );
  }

  calculateDiff(balance: number, expectedBalance: number): number {
    return Math.round((balance - expectedBalance) * 100) / 100;
  }

  getBankStatement(balances: AccountBalances): Attachment | undefined {
    return this.synchronizedAccount?.bankStatements.find(
      (attachment) => attachment.metadata?.bankStatementId === balances.id,
    );
  }

  async removeBalance(balance: AccountBalances): Promise<void> {
    if (!this.isRemoving && this.synchronizedAccount) {
      this.isRemoving = true;

      await this.synchronizedAccountStateService.updateAccount({
        ...this.synchronizedAccount,
        metadata: {
          ...this.synchronizedAccount.metadata,
          balances: this.synchronizedAccount.metadata.balances?.filter(
            (balanceIterated) => balanceIterated.id !== balance.id,
          ),
        },
      });

      this.isRemoving = false;
    }
  }

  async updateBalances(): Promise<void> {
    if (!this.isRefreshing && this.synchronizedAccount) {
      this.isRefreshing = true;
      await this.synchronizedAccountStateService.updateAccount(this.synchronizedAccount, {
        updateReconciliationExpectedBalances: true,
      });
      this.isRefreshing = false;
      this.cdr.markForCheck();
    }
  }

  trackById(index: number, item: AccountBalances): string {
    return item.id;
  }

  ngOnDestroy(): void {
    this.synchronizedAccountSubscription.unsubscribe();
  }
}
